import * as React from "react";
import {Game} from "../../models/game.model";
import {GamesService} from "../../services/games.service";
import {PLATORMS} from '../../models/platform';
import {YouTubePlayer} from '../common/youtubePlayer/youtubePlayer.component';

import './gameDetails.component.scss';

interface State {
    gameDetails?: Game
}

export class GameDetailsComponent extends React.Component<any, State> {
    private gameService: GamesService = new GamesService();

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.gameService.getGameById(this.props.match.params.id).then(r => {
            this.setState({
                gameDetails: r
            })
        })
    }

    render() {
        const game = this.state.gameDetails;
        if (!game) {
            return null
        }
        return (
            <div className='separate-route game-details-page'>

                <div className='game-banner' style={{backgroundImage: `url(${game.bannerUrl})`}}/>

                <div className='game-content ck-container '>
                    <div>
                        <h3><span className='color-green'>{game.title}</span>.</h3>
                        <p>
                            <strong>Category: </strong>
                            {Boolean(game.genre.length) && game.genre.map((item, idx) =>
                                <span key={`genre-${idx}`}>
                                    {item}
                                    {idx < game.genre.length - 1 ? ', ' : ''}
                                    </span>
                            )}
                        </p>
                        <p>
                            <strong>Platform: </strong>
                            {Boolean(game.platforms.length) && game.platforms.map((item, idx) =>
                                <span key={`platform-${idx}`}>
                                    {item.link ? <a className='ck-link' href={item.link}
                                                    target='_blank'>{PLATORMS[item.platform.toUpperCase()]}</a>
                                        : <span>{PLATORMS[item.platform.toUpperCase()]}</span>}
                                    {idx < game.platforms.length - 1 ? ', ' : ''}
                                </span>
                            )}
                        </p>
                        {game.link &&
                        <p><strong>Website: </strong> <a className='ck-link' href={game.link}
                                                         target='_blank'>{game.link}</a></p>
                        }
                        {game.developer &&
                        <p><strong>Developer: </strong> {game.developer}</p>
                        }
                        {game.publisher &&
                        <p><strong>Publisher: </strong> {game.publisher}</p>
                        }
                        {game.price &&
                        <p><strong>Price: </strong><span className='color-green'>{game.price}</span></p>
                        }

                        {Boolean(game.description.length) && game.description.map((item, idx) =>
                            <p key={`desc-${idx}`}>{item}</p>
                        )}
                    </div>
                    <div>
                        <h3><span className='color-green'>Video</span>.</h3>
                        {game.trailerYoutubeId &&
                        <YouTubePlayer
                            video={game.trailerYoutubeId}
                            autoplay="0"
                            rel="0"
                            modest="1"
                        />
                        }
                    </div>
                </div>

                {Boolean(game.screensUrl.length) &&
                <div className="game-screens">
                    <div className="ck-container">
                        <h3><span className='color-green'>Screens</span>.</h3>
                    </div>
                    <div className="ck-container-full">
                        {game.screensUrl.slice(0, 4).map((item, idx) =>
                            <div key={`screen-${idx}`} className='game-single-screen'
                                 style={{backgroundImage: `url(${item})`}}/>
                        )}
                    </div>
                </div>
                }
            </div>
        );
    }
}
