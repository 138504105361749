import {Game} from "../models/game.model";
import axios from 'axios';

export class GamesService {
    games: Game[];

    constructor() {}

    fromServer(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios.get('/api/games.json')
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
        })

    }

    getGames(limitTo?: number): Promise<Game[]> {
        return new Promise((resolve, reject) => {
            this.fromServer().then(g => {
                this.games = Game.ofCollection(g.data);
                const result = limitTo ? this.games.slice(0, limitTo) : this.games;
                resolve(result)
            })

        })
    }

    getGameById(id: string): Promise<Game> {
        return new Promise<Game>((resolve, reject) => {
            let game;
            this.getGames().then(() => {
                game = this.games.find(item => item.id === id);
                if (game) {
                    resolve(game)
                } else {
                    reject('cant find game');
                }
            })
        })
    }
}
