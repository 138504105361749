import * as React from 'react';
import { Timeline } from 'react-twitter-widgets';
import './socials.component.scss';
import * as Instafeed from 'instafeed.js';


interface Props {}

interface State {}

export class SocialsComponent extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        const feed = new Instafeed({
            get: 'user',
            userId: '222386905',
            template: '<a href="{{link}}"><img class="insta-image" src="{{image}}" /></a>',
            accessToken: '222386905.1677ed0.89df12a3bc5643ea87ddf5b33eba6fd1'
        });
        feed.run();
    }

    render() {
        return (
            <div className='socials-wrapper ck-container' >
                <div>
                    <Timeline
                        dataSource={{
                            sourceType: 'profile',
                            screenName: 'crunchingkoalas'
                        }}
                        options={{
                            // username: 'TwitterDev',
                            // height: '400'
                        }}
                        onLoad={() => console.log('Timeline is loaded!')}
                    />
                </div>

                <div>
                    <div id='instafeed'/>
                </div>
            </div>
        );
    }

}

// User ID: 222386905
// Username: crunchingkoalas
// CLIENT ID	efcc9648ee8b4afc82235adcbf9d1a5f
// accessToken: '222386905.1677ed0.89df12a3bc5643ea87ddf5b33eba6fd1'
