import * as React from "react";
import {Game} from "../../models/game.model";
import {MediaSquareComponent} from '../common/gameSquare/mediaSquare.component'
import {GamesService} from "../../services/games.service";
import {HeadingComponent} from '../common/heading/heading.component'
import {PLATORMS} from '../../models/platform';
import './games.component.scss';
import {Heading} from "../../models/heading.model";

interface State {
    games: Game[]
    gamesToDisplay: Game[]
    showFiltersContainer: boolean
    availablePlatforms: string[]
    availableScopeOfWork: string[]
    filterByPlatform: any;
    filterByScopeOfWork: any;

}

const gameDetailsHeading: Heading[] = Heading.ofCollection([
    {text: 'Games'},
    {text: 'we'},
    {text: 'worked on', color: 'green', withBreak: false},
    {text: '.'},
]);

export class GamesComponent extends React.Component<any, State> {

    static FILTER_PLATFORM_TYPE = 'platform';
    static FILTER_SCOPE_TYPE = 'scope';

    private gameService: GamesService = new GamesService();

    constructor(props) {
        super(props);
        this.state = {
            games: [],
            gamesToDisplay: [],
            showFiltersContainer: false,
            availablePlatforms: [],
            availableScopeOfWork: [],
            filterByPlatform: undefined,
            filterByScopeOfWork: undefined,
        }
    }

    componentDidMount(): void {
        window.scroll(0, 0);
        this.gameService.getGames()
            .then((games: Game[]) => {
                this.setState({
                    games,
                    gamesToDisplay: games,
                    ...this.collectAvailableFilters(games)
                })
            })
    }

    toggleFilterContainer(forceValue?: boolean): void {
        this.setState({
            showFiltersContainer: forceValue ? forceValue : !this.state.showFiltersContainer
        })
    }

    collectAvailableFilters(games: Game[] = []): any {
        const platforms = [];
        const scopeOfWork = [];

        games.forEach(game => {
            if (game.platforms.length) {
                game.platforms.forEach(platform => {
                    const idx = platforms.findIndex((savedPlatform) => {
                        return savedPlatform === platform.platform
                    })
                    if (idx === -1) {
                        platforms.push(platform.platform)
                    }
                })
            }
            if (game.scopeOfWork.length) {
                game.scopeOfWork.forEach(sow => {
                    const idx = scopeOfWork.findIndex((savedScope) => {
                        return savedScope === sow
                    });
                    if (idx === -1) {
                        scopeOfWork.push(sow)
                    }
                })
            }
        });

        return {
            availablePlatforms: platforms,
            availableScopeOfWork: scopeOfWork,
        }
    }

    selectFilter(type?: string, value?: string): void {
        if (!type || !value) {
            this.resetFilter();
        } else {
            if (type === GamesComponent.FILTER_PLATFORM_TYPE) {
                this.setState({
                    showFiltersContainer: false,
                    filterByPlatform: value,
                    gamesToDisplay: this.filterGames(value, this.state.filterByScopeOfWork)
                })

            } else if (type === GamesComponent.FILTER_SCOPE_TYPE) {
                this.setState({
                    showFiltersContainer: false,
                    filterByScopeOfWork: value,
                    gamesToDisplay: this.filterGames(this.state.filterByPlatform, value)
                })
            }
        }
    }

    filterGames(platform?: string, scopeOfWork?: string): Game[] {
        return this.state.games.filter(game => {
            let shouldReturn = true;
            if (shouldReturn && platform) {
                const idx = game.platforms.findIndex((p) => {
                    return p.platform === platform
                });
                if (idx === -1) {
                    shouldReturn = false
                }
            }

            if (shouldReturn && scopeOfWork) {
                const idx = game.scopeOfWork.findIndex((sow) => {
                    return sow === scopeOfWork
                });
                if (idx === -1) {
                    shouldReturn = false
                }
            }

            return shouldReturn
        })
    }

    resetFilter(leaveSingleFilter?: string): void {
        this.setState({
            gamesToDisplay: this.state.games,
            showFiltersContainer: false,
            filterByPlatform: leaveSingleFilter === GamesComponent.FILTER_PLATFORM_TYPE ? this.state.filterByPlatform : undefined,
            filterByScopeOfWork: leaveSingleFilter === GamesComponent.FILTER_SCOPE_TYPE ? this.state.filterByScopeOfWork : undefined,
        });
    }

    render() {
        const robot = require('../../assets/img/Hero_store.png');
        return (
            <div className='separate-route games-page'>

                <div className='games-header-row ck-container'>
                    <div>
                        <HeadingComponent
                            headingId='Games'
                            texts={gameDetailsHeading}
                            visibleOnStart={false}
                        />
                        <div
                            className='button-filter'
                            onClick={() => {
                                this.toggleFilterContainer();
                            }}
                        >Filters
                        </div>
                    </div>
                    <div>
                        <img src={robot} alt=""/>
                    </div>
                </div>

                <div className='ck-container-full games-square-wrapper'>
                    {this.state.gamesToDisplay.length ?
                        this.state.gamesToDisplay.map((game, index) =>
                            <div key={`page-game-square-${index}`}>
                                <MediaSquareComponent
                                    type='game'
                                    id={game.id}
                                    image={game.squareUrl}
                                    title={game.title}
                                />
                            </div>
                        )
                        :
                        <div className='ck-container'>
                            <p>nothing to show...
                                {(this.state.filterByPlatform || this.state.filterByScopeOfWork) &&
                                <span>, <span className='ck-link' onClick={() => {
                                    this.resetFilter()
                                }}>reset filters?</span></span>
                                }
                            </p>
                        </div>
                    }

                </div>
                <div className={`filter-container ${this.state.showFiltersContainer ? 'show' : ''}`}>
                    <div className='filter-box ck-container'>
                        <div className='close' onClick={() => {
                            this.toggleFilterContainer(false)
                        }}>
                            <div/>
                            <div/>
                            <div/>
                        </div>
                        <div>
                            <p><strong>platform:</strong></p>
                            <ul>
                                <li
                                    className={!this.state.filterByPlatform ? 'active' : ''}
                                    onClick={() => {
                                        this.resetFilter(GamesComponent.FILTER_SCOPE_TYPE)
                                    }}
                                >All,
                                </li>
                                {this.state.availablePlatforms.map((platform, index) =>
                                    <li
                                        key={`availablePlatform-${index}`}
                                        className={platform === this.state.filterByPlatform ? 'active' : ''}
                                        onClick={() => {
                                            this.selectFilter(GamesComponent.FILTER_PLATFORM_TYPE, platform)
                                        }}
                                    >{PLATORMS[platform]}{index < this.state.availablePlatforms.length - 1 &&
                                    <span className='comma'>,&nbsp;</span>}</li>
                                )}
                            </ul>
                        </div>
                        <div>
                            <p><strong>scope of work:</strong></p>
                            <ul>
                                <li
                                    className={!this.state.filterByScopeOfWork ? 'active' : ''}
                                    onClick={() => {
                                        this.resetFilter(GamesComponent.FILTER_PLATFORM_TYPE)
                                    }}
                                >All,
                                </li>
                                {this.state.availableScopeOfWork.map((sow, index) =>
                                    <li
                                        key={`availableScopeOfWork-${index}`}
                                        className={sow === this.state.filterByScopeOfWork ? 'active' : ''}
                                        onClick={() => {
                                            this.selectFilter(GamesComponent.FILTER_SCOPE_TYPE, sow)
                                        }}
                                    >{sow}{index < this.state.availableScopeOfWork.length - 1 &&
                                    <span className='comma'>,&nbsp;</span>}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
