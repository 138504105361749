import * as React from "react";
import './about.component.scss'
import {HeadingComponent} from '../common/heading/heading.component';
import {Heading} from "../../models/heading.model";

const whoWeAreHeading: Heading[] = Heading.ofCollection([
    {text: 'Who'},
    {text: 'are we', color: 'green', withBreak: false},
    {text: '?'},
]);

const ourStoryHeading: Heading[] = Heading.ofCollection([
    {text: 'Our'},
    {text: 'Story', color: 'green', withBreak: false},
    {text: '.'},
]);

export class AboutComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scroll(0, 0)
    }

    render() {
        const mainImage = require('../../assets/img/teamPhot_v3.jpg');
        const mouse = require('../../assets/img/mousecraft_secondaryArt_1500dpi.jpg');
        const photoSet1 = [
            require('../../assets/img/5team.jpg'),
            require('../../assets/img/6team.jpg'),
            require('../../assets/img/7team.jpg'),
        ];

        const photoSet2 = [
            require('../../assets/img/4team.jpg'),
            require('../../assets/img/8team.jpg'),
            require('../../assets/img/9team.jpg'),
        ];

        return (
            <div className='separate-route about-page'>
                <div className='main-image' style={{backgroundImage: `url(${mainImage})`}}/>
                <div className="text-row-one ck-container">
                    <div className="image">
                        <img src={mouse} alt=""/>
                    </div>
                    <div className="text">
                        <HeadingComponent
                            headingId={'about-who-we-are'}
                            texts={whoWeAreHeading}
                            visibleOnStart={false}
                        />
                        <p>We’re Crunching Koalas, a studio focused on bringing exceptional indie titles to wider
                            audiences by providing co-development, porting and publishing support.</p>
                    </div>
                </div>

                <div className="ck-container-full">
                    <div className="photo-row-one">
                        {photoSet1.map((photo, idx) =>
                            <div key={`photo-set-1-${idx}`} style={{backgroundImage: `url(${photo})`}}/>
                        )}
                    </div>
                </div>


                <div className="text-row-two">
                    <div className="ck-container">
                        <HeadingComponent
                            headingId={'our-story'}
                            texts={ourStoryHeading}
                            visibleOnStart={false}
                        />
                        <p>Everything started in 2012 - Crunching Koalas was formed by 5 folks: a talented but
                            completely inexperienced trio of indie developers and a duo of casual games industry
                            veterans. After 2 years of development, <strong>MouseCraft</strong> was born. It wasn’t a
                            huge sales hit, but
                            was praised for its clever level design, exceptional cartoon graphics and intelligent
                            gameplay. It was noticed by the media, received a solid Metacritic score, and later was a
                            part of PS Plus instant game collection, a Humble Bundle event, and was one of the titles in
                            the Indie Box collection. But it wasn’t enough - we couldn’t afford starting an entirely new
                            project. Things had to change - some people left the studio, new were hired, it was time to
                            change the direction.</p>
                        <p>With our PlayStation experience, we decided to start helping other indie developers in
                            bringing their games to consoles. Our first porting project resulted in a very successful
                            release of <strong>Sky Force Anniversary</strong>, on every possible console. We gained
                            confidence, a small
                            budget, and felt really good about our work, so we decided it’s time to go a bit further.
                            Our
                            business and marketing skills learned on MouseCraft, combined with the experience of working
                            with publishers, allowed us to come up with a viable, fair and transparent publishing offer.
                            That’s how we ported and released the console version of <strong>Lichtspeer</strong>. The
                            game gained tons of
                            publicity, and was well-received, so we didn’t had problems landing new projects. We
                            released <strong>BUTCHER</strong>, worked on <strong>Oh... Sir! The Insult
                                Simulator</strong> and brought <strong>Sky Force Reloaded</strong> to consoles.
                            The team grew and got more experienced so we decided to go for bigger projects
                            - we ported and published <strong>Regalia: Of Men and Monarchs</strong> for 3 platforms
                            simultaneously,
                            started working on getting <strong>Beat Cop</strong> and <strong>My Memory of Us</strong> to
                            consoles, and finally - brought <strong>This War of Mine: Complete Edition</strong> to
                            Nintendo Switch.</p>
                        <p>That’s the story of Crunching Koalas so far, but with the scope and quality of our current
                            projects, we‘re pretty confident it’s not the end yet!</p>
                    </div>
                </div>

                <div className='ck-container-full'>
                    <div className="photo-row-two">
                        {photoSet2.map((photo, idx) =>
                            <div key={`photo-set-2-${idx}`} style={{backgroundImage: `url(${photo})`}}/>
                        )}
                    </div>
                </div>


            </div>
        );
    }
}
