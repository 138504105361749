export class Heading {
    color?: string;
    text: string;
    animated?: boolean;
    withBreak?: boolean;

    static of(data: any): Heading {
        return new Heading(data);
    }

    static ofCollection(objects: any[] = []): Heading[] {
        return objects.map(obj => new Heading(obj));
    }

    constructor(data: any = {}) {
        this.color = data.color;
        this.text = data.text;
        this.animated = data.animated;
        this.withBreak = data.withBreak !== undefined ? data.withBreak : true
    }
}
