import * as React from 'react';
import {Link} from 'react-router-dom';
import {GamesService} from '../../services/games.service';
import {VideoComponent} from './video/video.component';
import {HeadingComponent} from '../common/heading/heading.component';
import {MediaSquareComponent} from '../common/gameSquare/mediaSquare.component';
import {Heading} from '../../models/heading.model';
import {Game} from '../../models/game.model';
import { SocialsComponent } from '../common/socials/socials.component';
import './home.component.scss';

interface State {
    gamesToDisplay: Game[];
    ourGamesOnView: boolean;
    whoWeAreOnView: boolean;
}

const ourGames: Heading[] = Heading.ofCollection([
    {text: 'Games'},
    {text: 'we'},
    {text: 'worked on', color: 'green', withBreak: false},
    {text: '.'},
]);

const whoWeAreHeading: Heading[] = Heading.ofCollection([
    {text: 'Who'},
    {text: 'are we', color: 'green', withBreak: false},
    {text: '?'},
]);

export class HomeComponent extends React.Component<any, State> {

    window: Window;

    private gameService: GamesService = new GamesService();

    constructor(props) {
        super(props);
        this.state = {
            gamesToDisplay: [],
            ourGamesOnView: false,
            whoWeAreOnView: false,
        };

    }

    componentDidMount() {
        window.scroll(0, 0);
        this.gameService.getGames(4).then((r: Game[]) => {
            this.setState({
                gamesToDisplay: r,
            });
        });
    }

    render() {
        const bunnyImage = require('../../assets/img/casualProductImage.png');
        const whoWeAreImage = require('../../assets/img/teamPhot_v3.jpg');
        const heart = require('../../assets/img/heart.svg');
        const platformLogotypes = [
            require('../../assets/img/p1.svg'),
            require('../../assets/img/p4.svg'),
            require('../../assets/img/p8.svg'),
            require('../../assets/img/p5.svg'),
            require('../../assets/img/p6.svg'),
            require('../../assets/img/p7.svg'),
            require('../../assets/img/p3.svg'),
            require('../../assets/img/p2.svg'),
            require('../../assets/img/p9.svg'),
        ];

        return (
            <div className='separate-route home-page'>
                <VideoComponent/>


                <section className='games-section'>
                    <div className='ck-container'>
                        <div className='game-title-wrapper'>
                            <div data-scroll='ourGames'>
                                <HeadingComponent
                                    headingId={'ourGames'}
                                    texts={ourGames}
                                    visibleOnStart={false}
                                />
                                <p>Check out all the remarkable games we had the pleasure to work on and help to get the
                                    visibility and attention they deserve.</p>
                            </div>
                            <div>
                                <img src={bunnyImage} alt=''/>
                            </div>
                        </div>
                    </div>
                    <div className='games-square-wrapper ck-container-full'>
                        {this.state.gamesToDisplay && this.state.gamesToDisplay.map((game, index) =>
                            <div key={`game-square-${index}`}>
                                <MediaSquareComponent
                                    type='game'
                                    id={game.id}
                                    image={game.squareUrl}
                                    title={game.title}
                                />
                            </div>
                        )}
                    </div>
                    <div className='ck-container discover-more-link'>
                        <Link to='/games' className='ck-link ck-link-arrow'>Discover more Games</Link>
                    </div>
                </section>

                <section className='who-we-are-section'>
                    <div style={{backgroundImage: `url(${whoWeAreImage})`}}/>
                    <div>
                        <HeadingComponent
                            headingId={'whoWeAre'}
                            texts={whoWeAreHeading}
                            visibleOnStart={false}
                        />
                        <p>We’re Crunching Koalas, a studio focused on bringing exceptional indie titles to wider
                            audiences by providing co-development, porting and publishing support.</p>
                        <br/>
                        <Link to='/about' className='ck-link ck-link-arrow read-more'>Read more</Link>
                    </div>
                </section>
                <section className='motto'>
                    <div className='ck-container'>
                        <h2>Because everyone <span className='color-green'>loves Koalas</span> <img src={heart} alt=""/>
                        </h2>
                    </div>
                </section>
                <section className='platform-logotypes'>
                    <div className='platforms-wrapper ck-container'>
                        {platformLogotypes.map((item, index) =>
                            <div key={`platforms-logo-${index}`}>
                                <img src={item} alt=""/>
                            </div>
                        )}
                    </div>
                </section>
                <section>
                    <SocialsComponent />
                </section>
            </div>
        );
    }
}
