import * as React from "react";
import axios from 'axios';
import {Heading} from "../../../models/heading.model";
import {HeadingComponent} from '../heading/heading.component';

import './footer.component.scss'

const whoWeAreHeading: Heading[] = Heading.ofCollection([
    {text: 'Contact', color: 'green', withBreak: false},
    {text: '.', color: 'white'},
]);

interface State {
    value: string
    valid: boolean
    message: string
}

export class FooterComponent extends React.Component<any, State> {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            valid: true,
            message: ''
        }
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            valid: true,
            value: e.target.value,
            message: '',
        })
    }

    handleKeyPress(e: any) {
        if (e.key === 'Enter') {
            this.sendRequest(this.state.value)
        }
    }

    validateInput(mail) {
        const patter = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
        const rgx = new RegExp(patter);

        if (!mail) {
            this.setState({
                valid: false,
                message: 'Please, enter your e-mail address.'
            });
            return false;
        }
        if (!rgx.test(mail)) {
            this.setState({
                valid: false,
                message: 'Please, enter your correct e-mail address.'
            });
            return false
        }
        return true;
    }

    sendRequest(mail: string):void {
        if (this.validateInput(mail)) {
            axios
                .post('/api/addToNewsletter/index.php', {mail})
                .then(r => {
                    this.setState({
                        valid: Boolean(Number(r.data.code) === 0),
                        message: r.data.message,
                        value: ''
                    })
                })
                .catch(e => {
                    this.setState({
                        valid: false,
                        message: 'Ops!, an error occured.',
                    })
                })
        }
    }

    render() {
        const facebook = require('../../../assets/img/fb.svg');
        const twitter = require('../../../assets/img/twit.svg');
        const instagram = require('../../../assets/img/ig.svg');
        const inputArrow = require('../../../assets/img/arrow_green.svg');

        return (
            <footer>
                <div className="ck-container">
                    <HeadingComponent
                        headingId='HomeFooter'
                        texts={whoWeAreHeading}
                        visibleOnStart={false}
                    />
                </div>
                <div className="ck-container footer-content">
                    <div>

                        <p>Aleja Jana Pawła II 61 / 310, 01-031 Warsaw, Poland</p>
                        <p>
                            General inquiries: <br/>
                            <a href="mailto:info@crunchingkoalas.com" className='ck-link'>info@crunchingkoalas.com</a>
                        </p>
                        <p>
                            Publishing/porting inquiries: <br/>
                            <a href="mailto:publishing@crunchingkoalas.com"
                               className='ck-link'>publishing@crunchingkoalas.com</a>
                        </p>

                        <div className='socials'>
                            <a href='https://www.facebook.com/crunchingkoalas/' target='_blank'><img src={facebook} alt=''/></a>
                            <a href='https://twitter.com/crunchingkoalas' target='_blank'><img src={twitter} alt=''/></a>
                            <a href='https://www.instagram.com/crunchingkoalas/' target='_blank'><img src={instagram} alt=''/></a>
                        </div>
                    </div>

                    <div className='newsletter'>
                        <p>sign up to our newsletter</p>
                        <div className='input-box'>
                            <label>Type in your email:</label>
                            <div className='input-wrapper'>
                                <input
                                    type="text"
                                    onChange={(e) => {
                                        this.handleInputChange(e);
                                    }}
                                    value={this.state.value}
                                    onKeyPress={(e) => {
                                        this.handleKeyPress(e)
                                    }}
                                />
                                <div
                                    className="input-arrow"
                                    onClick={() => {
                                        this.sendRequest(this.state.value)
                                    }}
                                >
                                    <img src={inputArrow} alt=""/>
                                </div>
                            </div>
                            {this.state.message &&
                                <p className={`message ${this.state.valid ? 'success' : 'error'}`}>{this.state.message}</p>
                            }
                        </div>
                    </div>


                </div>
                <div className="ck-container">
                    <p className='maybe-red'>made with <span className='ck-link'>♥</span> by <a className='ck-link' href="http://maybe.red" target='_blank'>maybe.red</a></p>
                </div>
            </footer>
        );
    }
}
