import * as React from "react";
import {Link, NavLink} from 'react-router-dom';
import './navbar.component.scss'

interface State {
    open: boolean;
}

export class NavComponent extends React.Component<any, State> {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

    }

    toggleMenu(newValue?: boolean) {
        this.setState({
            open: newValue ? newValue : !this.state.open
        })
    }

    render() {
        const logo = require('../../../assets/img/logo_v2.svg');
        return (
            <nav>
                <div className='ck-container nav-wrapper'>
                    <div className='logo'>
                        <Link to='/'><img src={logo} alt=""/></Link>
                    </div>
                    <div className={`burger-icon ${this.state.open ? 'change' : ''}`} onClick={() => {
                        this.toggleMenu();
                    }}>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <ul>
                        {this.props.nav.map((item, index) => {
                            return item.displayInNav ?
                                <li key={`nav-li-${index}`}>
                                    <NavLink to={item.path} exact
                                             activeStyle={{color: '#76b72a'}}>{item.displayText}</NavLink>
                                </li>
                                : null;
                        })}
                    </ul>
                </div>
                {this.state.open &&
                <div className="mobile-menu">
                    <ul>
                        {this.props.nav.map((item, index) => {
                            return item.displayInNav ?
                                <li key={`mobile-menu-li-${index}`} onClick={() => {
                                    this.toggleMenu(false);
                                }}>
                                    <NavLink to={item.path}>{item.displayText}</NavLink>
                                </li>
                                : null;
                        })}
                    </ul>
                </div>
                }
            </nav>
        );
    }
}
