import {Platform} from "./platform";

const HOST_URL = "https://crunchingkoalas.com/staticAssets/games/";
const YOUTUBE_URL = "https://www.youtube.com/watch?v=";

export class Game {
    id: string;
    logo: string;
    banner: string;
    bannerUrl: string;
    square: string;
    squareUrl: string;
    title: string;
    description: string[];
    screens: string[];
    screensUrl: string[];
    trailer: string;
    link: string;
    developer: string;
    publisher: string;
    genre: string[];
    platforms: Platform[];
    price: string;
    scopeOfWork: string[];
    trailerYoutubeId: string;

    static of(data: any): Game {
        return new Game(data);
    }

    static ofCollection(objects: any[] = []): Game[] {
        return objects.map(obj => new Game(obj));
    }

    constructor(data: any = {}) {
        this.id = data.id;
        this.title = data.title;
        this.banner = data.banner || undefined;
        this.bannerUrl = Game.withHost(data.banner);
        this.square = data.square || undefined;
        this.squareUrl = Game.withHost(data.square);
        this.genre = data.genre || [];
        this.platforms = data.platforms || [];
        this.link = data.link;
        this.developer = data.developer;
        this.publisher = data.publisher;
        this.price = data.price;
        this.description = data.description || [];
        this.trailer = data.trailer;
        this.trailerYoutubeId = Game.getYoutubeIdFromUrl(data.trailer);
        this.screens = data.screens;
        this.screensUrl = Game.screensWithHost(data.screens);
        this.scopeOfWork = data.scope_of_work || [];
    }

    static screensWithHost(screens) {
        if (screens && screens.length) {
            let result = [];
            screens.forEach(item => {
                result.push(`${HOST_URL}${item}`)
            });
            return result;
        }
        return screens;
    }

    static withHost(con: string): any {
        return con ? `${HOST_URL}${con}` : undefined;
    }

    static getYoutubeIdFromUrl(url): any {
        if (url.includes(YOUTUBE_URL)) {
            return url.split(YOUTUBE_URL)[1]
        } else {
            return undefined
        }
    }
}
