import * as React from "react";
import './mediaSquare.component.scss';
import {Link} from 'react-router-dom';

interface Props {
    image?: string;
    title?: string;
    type: string;
    id: string;
}

export class MediaSquareComponent extends React.Component<Props, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Link to={`/game/${this.props.id}`}>
                <div className='media-box'>
                    <div className='media-box-image' style={{backgroundImage: `url(${this.props.image})`}}/>
                    <div className="media-box-content">
                        <p>{this.props.title}</p>
                    </div>
                </div>
            </Link>
        );
    }
}
