import * as React from "react";
import './video.component.scss'

export const VideoComponent = () => {
    const video = require('../../../assets/video/ck_website_video.mp4');
    const video1stFrame_1 = require('../../../assets/img/video1stFrame_1.png');

    return (
        <div className='video-container' style={{backgroundImage: `url(${video1stFrame_1})`}}>
            <video autoPlay muted loop id="myVideo">
                <source src={video} type="video/mp4"/>
            </video>
            <div
                onClick={() => {
                    window.scrollTo(0, window.innerHeight - 100)
                }}
                className="arrow bounce"
            />
        </div>
    );
};
