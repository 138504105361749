import * as React from "react";
import './youtubePlayer.componenet.scss';

export const YouTubePlayer = (props) => {
    const videoSrc = "https://www.youtube.com/embed/" +
        props.video + "?autoplay=" +
        props.autoplay + "&rel=" +
        props.rel + "&modestbranding=" +
        props.modest;
    return (
        <div className="youtube-player-container">
            <iframe
                className='player'
                width='100%'
                height='100%'
                src={videoSrc}
                frameBorder='0'
            />
        </div>
    );
};
