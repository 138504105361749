export interface Platform {
    platform: string;
    link?: string
}

export enum PLATORMS {
    PS4 = 'PlayStation 4',
    XBOX_ONE = 'Xbox One',
    NINTENDO_SWITCH = 'Nintendo Switch',
    STEAM = 'Steam',
    NETFLIX = 'Netflix', 
 LUNA = 'Amazon Luna',
    GOG = 'GOG.com',
    PS3 = 'PlayStation 3',
    PS_VITA = 'PlayStation Vita',
    NINTENDO_WII_U = 'Nintendo Wii U',
    IOS = 'iOS',
    GOOGLE_PLAY = 'Google Play',
    AMAZON_APPSTORE = 'Amazon Appstore',
    OCULUS_STORE = 'Oculus Store',
    WIN_10 = 'Windows 10',
    MAC_APP_STORE = 'Mac App Store'
}
