import * as React from "react";
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { NavComponent } from './common/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { GamesComponent } from './games/games.component';
import { GameDetailsComponent } from './gameDetails/gameDetails.component';
import { FooterComponent } from "./common/footer/footer.component";
import './APP.scss';

const Contact = () => {
    return (
        <div className='contact-fake'>
            <div className='bg'/>
        </div>
    )
};


const nav = [
    {path: '/',         displayText: 'home',    displayInNav: true,     component: HomeComponent,           exact: true},
    {path: '/games',    displayText: 'games',   displayInNav: true,     component: GamesComponent,          exact: false},
    {path: '/about',    displayText: 'about us',   displayInNav: true,     component: AboutComponent,                   exact: false},
    {path: '/contact',  displayText: 'contact', displayInNav: true,     component: Contact,                 exact: false},
    {path: '/game/:id', displayText: undefined, displayInNav: false,    component: GameDetailsComponent,    exact: false},

];

export default class App extends React.Component<any, any> {
    render() {
        return (
            <Router>
                <div className="ck-all">
                    {/*<Nav />*/}
                    <NavComponent nav={nav} />
                    {nav.map((item, index) => {
                        return <Route key={`nav-route-${index}`} exact={item.exact} path={item.path} component={item.component} />
                    })}
                    <FooterComponent/>
                </div>
            </Router>
        );
    }
}
