import * as React from "react";
import './heading.component.scss';
import {Heading} from "../../../models/heading.model";
import {isNegativeNumberLiteral} from "tslint";

interface Props {
    headingId: string;
    texts: Heading[];
    visibleOnStart?: boolean
}

interface State {
    texts: Heading[];
    visibleOnStart: boolean
    animated: boolean
}

export class HeadingComponent extends React.Component<Props, State> {
    window: Window;
    header: HTMLElement;

    constructor(props) {
        super(props);
        this.state = {
            texts: this.props.texts,
            visibleOnStart: !!this.props.visibleOnStart,
            animated: !!this.props.visibleOnStart
        }
    }

    componentDidMount(): void {
        this.window = window;
        this.header = document.querySelectorAll(`[data-scroll='${this.props.headingId}']`)[0] as HTMLElement;


        if (!this.state.animated) {
            if (this.shouldBeVisible) {
                this.startAnimate()
            } else {
                this.addScrollListener()
            }
        }
    }

    startAnimate(): void {
        this.state.texts.forEach((item, index) => {
            setTimeout(() => {
                item.animated = true;

                if (index < this.state.texts.length - 1) {
                    this.setState({
                        texts: this.state.texts
                    })
                } else {
                    this.setState({
                        texts: this.state.texts,
                        animated: true
                    });
                    this.removeScrollListener();
                }

            }, 200 * (index + 1))
        });
    }

    addScrollListener() {
        window.addEventListener('scroll', this.handleScroll.bind(this), true);
    }

    removeScrollListener() {
        window.removeEventListener('scroll', this.handleScroll.bind(this), true);
    }

    handleScroll() {
        if (this.shouldBeVisible) {
            this.startAnimate();
        }
    }

    get shouldBeVisible(): boolean {
        if (!this.header) {
            return false
        }
        const scroll = this.window.scrollY;
        const window = this.window.innerHeight;
        const offsetTop: number = this.header.offsetTop;
        return scroll + window > offsetTop
    }

    render() {
        return (
            <div
                className={`heading ${this.state.visibleOnStart ? 'with-out-animation' : ''}`}
                data-scroll={this.props.headingId}
            >
                <h1>
                    {this.state.texts.slice(0, this.state.texts.length - 2).map((item, index) => {
                        return (
                            <span
                                key={`hc-first-${index}`}
                                className={`${item.animated ? 'animated' : ''} ${item.color}`}
                            ><span>{item.text}</span>{item.withBreak && <span>&nbsp;</span>}</span>
                        )
                    })}
                </h1>
                <h1 style={{whiteSpace: 'nowrap'}}>
                    {this.state.texts.slice(this.state.texts.length - 2, this.state.texts.length).map((item, index) => {
                        return (
                            <span
                                key={`hc-sec-${index}`}
                                className={`${item.animated ? 'animated' : ''} ${item.color}`}
                            ><span>{item.text}</span>{item.withBreak && <span>&nbsp;</span>}</span>
                        )
                    })}
                </h1>
            </div>
        )
    }

}
